<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>permissions</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'Home'}">Home</router-link>
              </li>
              <li class="breadcrumb-item active">permissions</li>
            </ol>
          </div>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="card" id="permissions-add">
        <div class="overlay" v-if="is_loading">
          <i class="fas fa-2x fa-sync-alt fa-spin"></i>
        </div>
        <form @submit.prevent="handleSubmission()" method="POST" novalidate>
          <div class="card-header">
            <h3 class="card-title" v-if="!is_edit">Create</h3>
            <h3 class="card-title" v-if="is_edit">Edit</h3>
            <div class="card-tools">
              <router-link class="btn btn-info" :to="{name: 'Permissions'}"><i class="fas fa-list"></i> List
              </router-link>
            </div>
          </div>
          <div class="card-body">
            <div class="form-group required">
              <label for="slug" class="control-label">Slug</label>
              <input type="text" id="slug" name="slug"
                     v-bind:class="{'has-error' : errors.has('slug')}"
                     v-validate="'required'"
                     v-model="permissions.slug" class="form-control name"
                     placeholder="slug">
              <div class="help text-danger" v-show="errors.has('slug')">
                {{ errors.first('slug') }}
              </div>
            </div>
            <div class="form-group required">
              <label for="name" class="control-label">Name</label>
              <input type="text" id="name" name="name"
                     v-bind:class="{'has-error' : errors.has('name')}"
                     v-validate="'required'"
                     v-model="permissions.name" class="form-control name"
                     placeholder="Name">
              <div class="help text-danger" v-show="errors.has('name')">
                {{ errors.first('name') }}
              </div>
            </div>

            <div class="form-group required">
              <label class="control-label">Organization</label>
              <multiselect
                name="Organization"
                v-model="permissions.organization_id"
                :options="organizations.map(org => org.id)"
                :custom-label="opt => organizations.find(x => x.id == opt).org_name"
                placeholder="Pick Organization" :searchable="true" :multiple="false"
                v-bind:class="{'has-error' : errors.has('Organization')}"
                v-validate="'required'"
                class="organization">
              </multiselect>
              <div class="help text-danger" v-show="errors.has('Organization')">
                {{ errors.first('Organization') }}
              </div>
            </div>

            <div class="form-group">
              <label for="http_method" class="control-label">HTTP method</label>
              <multiselect
                name="http_method"
                v-model="permissions.http_method"
                placeholder="Pick http-methods"
                id="http_method" open-direction="bottom"
                :multiple="true"
                :options="http_method_static.map(http => http)"
                :custom-label="opt => http_method_static.find(x => x == opt)"
                :searchable="true" :loading="isSelectorLoading" :internal-search="false"
                :clear-on-select="true" :close-on-select="true" :options-limit="300" :limit="3"
                :max-height="600" :show-no-results="false" :hide-selected="true"
                @search-change="getALLMethods"
              >
              </multiselect>
            </div>

            <div class="form-group required">
              <label for="http_path" class="control-label">HTTP path</label>
              <input type="text" id="http_path" name="http_path"
                     v-bind:class="{'has-error' : errors.has('http_path')}"
                     v-validate="'required'"
                     v-model="permissions.http_path" class="form-control name"
                     placeholder="Http Path">
              <div class="help text-danger" v-show="errors.has('http_path')">
                {{ errors.first('http_path') }}
              </div>
            </div>

          </div>
          <div class="card-footer">
            <button type="submit" v-if="!is_edit" class="btn btn-primary">Submit</button>
            <button type="submit" v-if="is_edit" class="btn btn-primary">Update</button>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import {PermissionAPI, CommonAPI} from "@/services/api";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
export default {
  name: 'PermissionForm',
  metaInfo: {
    title: "Permission | Dotlines"
  },
  components: {
    DatePicker
  },
  data: () => ({
    permissions: {},
    organizations: [],
    parents: [],
    http_method_static: [],
    isSelectorLoading: false,
    is_loading: false,
    is_edit: false,
    params: {},
  }),
  methods: {
    handleSubmission() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.is_edit) {
            PermissionAPI.update(this.params.id, this.permissions).then(response => {
              this.$router.push({name: 'Permissions'});
              this.$toaster.success(response.message)
            }).catch(error => {
              console.log(error);
              this.$setErrorsFromResponse(error);
            });
          } else {
            PermissionAPI.store(this.permissions).then(response => {
              this.$router.push({name: 'Permissions'});
              this.$toaster.success(response.message)
            }).catch(error => {
              this.$setErrorsFromResponse(error);
            });
          }
        }
      });
    },
    getOrganizations: function () {
      CommonAPI.getAllOrganizations().then(organizations => {
        this.organizations = organizations;
        console.log('permissions', organizations);
      });
    },
    getALLMethods: function () {
      this.http_method_static = [
        'GET',
        'POST',
        'PUT',
        'DELETE',
        'PATCH',
        'OPTIONS',
        'HEAD'
      ]

    },
    async permissionsShow(id) {
      await PermissionAPI.show(id).then(permissions => {
        if (!_.isEmpty(permissions.organization) && typeof (permissions.organization) !== undefined) {
          this.organizations[0] = permissions.organization;
        }
        if (!_.isEmpty(permissions.http_method_static) && typeof (permissions.http_method_static) !== undefined) {
          this.http_method_static[0] = permissions.http_method_static;
        }

        this.permissions = permissions;
      });
    },
    async initialLoad() {
      this.is_loading = true;
      this.params = this.$route.params;
      if (!_.isEmpty(this.params) && this.params.id !== undefined) {
        this.is_edit = true;
      }
      if (this.is_edit) {
        await this.permissionsShow(this.params.id);
      }
      await this.getOrganizations();
      this.getALLMethods();
      this.is_loading = false;
    }

  },
  mounted() {
    this.initialLoad()
  }
}
</script>
