<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Roles</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'Home'}">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Roles</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <!-- Main content -->
    <section class="content" v-if="has_permission === true">
      <div class="container-fluid">
        <div class="card">
          <div class="card-header">
            <a href="javascript:void(0)" class="btn btn-info" @click="showFilter = !showFilter"><i
              class="fa fa-filter"></i>Filter</a>
            <div class="card-tools">
              <router-link :to="{name: 'RoleAdd'}" class="btn btn-success">
                <i class="fas fa-plus"></i> New
              </router-link>
              <button class="btn btn-info" @click="exportFile()">
                <i class="fas fa-download"></i> Export
              </button>
            </div>
          </div>
          <form @submit.prevent="getRoles" method="GET" novalidate>
            <div class="card-body" v-if="showFilter">
              <div class="form-row">
                <div class="col-md-6">
                  <div class="form-group form-row">
                    <label class="col-md-3 col-form-label" for="name">Name</label>
                    <div class="col-md-9">
                      <input type="text" class="form-control" id="name" v-model="filter.name" placeholder="Name">
                    </div>
                  </div>
                </div>

              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-9 offset-md-3">
                      <button type="submit" class="btn btn-common m-2px"><i class="fas fa-search"></i> Search</button>
                      <button type="reset" class="btn btn-warning" @click="resetFilter()"><i class="fas fa-undo"></i> Reset</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div class="overlay" v-if="is_loading">
            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
          </div>
          <div class="card-body table-responsive-md p-0">

            <table class="table">
                <thead>
                <tr>
                  <th style="width: 10px">ID</th>
                  <th>Slug <sort-icons :_sort="'slug'"/></th>
                  <th>Name <sort-icons :_sort="'name'"/></th>
                  <th>Organization</th>
                  <th>Permission</th>
                  <th class="text-right">Action</th>
                </tr>
                </thead>
                <tbody class="roles-roles">
                <tr v-for="(val, index) in roles.data">
                  <td>{{ val.id }}</td>
                  <td>{{ val.slug }}</td>
                  <td>{{ val.name }}</td>
                  <td>{{ !_.isEmpty(val.organization) ? val.organization.org_name : 'N/A' }}</td>
                  <td><span class="badge bg-success" v-for="(permissionValue, permissionIndex) in val.permissions" :key="permissionIndex">{{ permissionValue.name }}</span>
                  </td>
                  <td class="text-right">
                    <router-link :to="{ name: 'RoleView', params: { id: val.id }}" class="btn btn-info btn-sm m-2px">
                      <i class="fas fa-eye"></i>
                    </router-link>
                    <router-link :to="{ name: 'RoleEdit', params: { id: val.id }}" class="btn btn-info btn-sm m-2px">
                      <i class="fas fa-pencil-alt"></i>
                    </router-link>
                    <a class="btn btn-danger btn-sm m-2px" href="javascript:void(0)" @click="destroy(val.id, index)"><i
                      class="fas fa-trash"></i></a>
                  </td>
                </tr>
                </tbody>
              </table>

            <div class="p-4" v-if="_.isEmpty(roles.data)">
              <div class="alert alert-default-warning" role="alert">
                No data found!
              </div>
            </div>
          </div>
          <div class="card-footer">
            <vue-pagination
              :pagination="roles"
              v-if="roles.total > roles.per_page"
              @paginate="getRoles()"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="content" v-if="has_permission == false">
      <div class="alert alert-danger alert-dismissible">
        <h5><i class="icon fas fa-ban"></i> Permission denied!</h5>
      </div>
    </section>
  </div>
</template>

<script>
import VuePagination from '@/components/partials/PaginationComponent'
import SortIcons from '@/components/utils/SortIcons'
import DatePicker from 'vue2-datepicker'
import axios from 'axios'
import 'vue2-datepicker/index.css'
import { RoleAPI } from '@/services/api'
import { Notification } from 'element-ui'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const fileDownload = require('js-file-download')

export default {
  name: 'RoleList',
  metaInfo: {
    title: 'Role | Dotlines'
  },
  components: {
    DatePicker,
    VuePagination,
    SortIcons
  },
  data: () => ({
    roles: {
      total: 0,
      per_page: 1,
      from: 1,
      to: 0
    },
    // api_url: '',
    showFilter: false,
    filter: {},
    // dateRange: '',
    // pre: [],
    is_loading: false,
    has_permission: true
  }),
  mounted: function () {
    if (this.isMobile()) {
      window.$(document).find('.push-menu-class').trigger('click')
    }
    this.getRoles()
    this.$root.$on('sort-data', () => {
      this.$root.$emit('show-icon')
      this.getRoles()
    })
  },
  methods: {
    exportFile: function () {
      this.is_loading = true
      axios.get('/admin-roles-export', { responseType: 'blob' })
        .then(response => {
          fileDownload(response.data, 'admin-roles.xlsx')
        })
        .catch((error) => {
          console.log('error ' + error)
        })
        .finally(() => {
          this.is_loading = false
          Notification.success({ title: 'Export Completed', message: 'Export operation completed successfully', type: 'success' })
        })
    },
    getRoles () {
      this.is_loading = true
      const query = { ...this.filter, ...this.$route.query }
      RoleAPI.index(query).then(roles => {
        this.roles = roles
      }).catch(error => {
        if (error.response.status === 403) {
          this.has_permission = false
        }
      }).finally(() => {
        this.is_loading = false
      })
    },
    destroy: function (id, index) {
      this.$swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.is_loading = true
          RoleAPI.destroy(id)
            .then(response => {
              // console.log('deleted this:: ', response)
              this.roles.data.splice(index, 1)
              this.roles.total--
              this.$toaster.success(response.message)
            })
            .catch(error => {
              console.log('Could not delete this:: ', error)
              this.$swal.fire('Failed!', 'Failed to delete.', 'error')
            }).finally(() => {
              this.is_loading = false
            })
        }
      })
    },
    resetFilter () {
      const query = Object.assign({}, this.$route.query)
      if (Object.keys(query).length > 0) {
        delete query._sort
        delete query._sort_type
        delete query.page
        this.$router.replace({ query })
      }
      this.filter = {}
      this.getRoles()
    }
  }
}
</script>
