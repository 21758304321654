<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Roles</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'Home'}">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Roles</li>
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <section class="content">

      <!-- Default box -->
      <div class="card" id="users">
        <div class="overlay" v-if="is_loading">
          <i class="fas fa-2x fa-sync-alt fa-spin"></i>
        </div>
        <div class="card-header">
          <h3 class="card-title">Role Detail</h3>
          <div class="card-tools">
            <router-link class="btn btn-info" :to="{name: 'Role'}"><i class="fas fa-list"></i> List
            </router-link>
          </div>
        </div>
        <div class="card-body">
          <div class="custom-list">
            <ul>
              <li><strong>ID</strong> {{ value.id }}</li>
              <li><strong>Slug</strong> {{ value.slug }}</li>
              <li><strong>Name</strong>{{ value.name }}</li>
              <li><strong>Organization</strong>{{ !_.isEmpty(value.organization) ? value.organization.org_name : '' }}
              </li>
              <li><strong>Permission
                Name</strong>{{ !_.isEmpty(value.custom_permission) ? value.custom_permission.permission_name : '' }}
              </li>
              <li><strong>Permissions</strong><span class="badge bg-success"
                                                    v-for="(permissionValue, permissionIndex) in value.permissions" :key="permissionIndex">{{
                  permissionValue.name
                }}</span></li>
              <li><strong>Created At</strong>{{ !_.isEmpty(value.created_at) ? value.created_at : '' }}</li>
              <li><strong>Updated At</strong>{{ !_.isEmpty(value.updated_at) ? value.updated_at : '' }}</li>
            </ul>
          </div>
        </div>
        <!-- /.card-body -->
        <div class="card-footer">

        </div>
        <!-- /.card-footer-->
      </div>
      <!-- /.card -->
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
import { RoleAPI } from '@/services/api'

export default {
  name: 'RoleView',
  metaInfo: {
    title: 'Role | Dotlines'
  },
  components: {
    //
  },
  data: () => ({
    params: {},
    value: {},
    is_loading: false
  }),
  mounted: function () {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _this = this
    _this.param = _this.$route.params
    _this.getRoleDetail(_this.param.id)
  },
  methods: {
    getRoleDetail: function (id) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const _this = this
      _this.is_loading = true
      RoleAPI.show(id).then(roles => {
        console.log('response.data::', roles)
        _this.value = roles
        _this.is_loading = false
      }).catch((error) => {
        console.log('error ' + error)
        _this.is_loading = false
      })
    }
  }
}
</script>
