<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Operation Log</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><router-link :to="{name: 'Home'}">Home</router-link></li>
              <li class="breadcrumb-item active">Operation Log</li>
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>

    <section class="content">
      <div class="card">
        <div class="card-header">
          <a href="javascript:void(0)" class="btn btn-info" @click="showFilter = !showFilter"><i
            class="fa fa-filter"></i>Filter</a>

<!--          <div class="card-tools">-->
<!--            <router-link :to="{ name: 'TraningInfoAdd'}" class="btn btn-success">-->
<!--              <i class="fas fa-plus"></i> New-->
<!--            </router-link>-->
<!--          </div>-->
        </div>
        <div class="overlay" v-if="is_loading">
            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
        </div>
        <form @submit.prevent="getList" method="GET" novalidate>
          <div class="card-body" v-if="showFilter">
            <div class="form-row">
              <div class="col-md-6">
                <div class="form-group form-row">
                  <label class="col-md-3 col-form-label" for="id">ID</label>
                  <div class="col-md-9">
                    <input type="text" class="form-control" id="id" v-model="filter.id" placeholder="id">
                  </div>
                </div>
                <div class="form-group form-row">
                  <label class="col-md-3 col-form-label" for="method">Method</label>
                  <div class="col-md-9">
                    <select class="form-control" name="method"
                            id="method" v-model="filter.method" placeholder="method">
                      <option value=""></option>
                      <option value="GET">GET</option>
                      <option value="POST">POST</option>
                      <option value="PUT">PUT</option>
                      <option value="DELETE">DELETE</option>
                      <option value="OPTIONS">OPTIONS</option>
                      <option value="PATCH">PATCH</option>
                      <option value="LINK">LINK</option>
                      <option value="UNLINK">UNLINK</option>
                      <option value="COPY">COPY</option>
                      <option value="HEAD">HEAD</option>
                      <option value="PURGE">PURGE</option>
                    </select>

                  </div>
                </div>
                <div class="form-group form-row">
                  <label class="col-md-3 col-form-label" for="ip">ip</label>
                  <div class="col-md-9">
                    <input type="text" class="form-control" id="ip" v-model="filter.ip" placeholder="ip">
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group form-row">
                  <label class="col-md-3 col-form-label" for="user">User</label>
                  <div class="col-md-9">
                    <multiselect
                      v-model="filter.user_id"
                      :options="users.map(user => user.id)"
                      :custom-label="opt => users.find(x => x.id === opt).name"
                      placeholder="Pick a User" :searchable="true" :multiple="false"
                      id="user">
                    </multiselect>
                  </div>
                </div>
                <div class="form-group form-row">
                  <label class="col-md-3 col-form-label" for="path">Path</label>
                  <div class="col-md-9">
                    <input type="text" class="form-control" id="path" v-model="filter.path" placeholder="path">
                  </div>
                </div>
                <!-- Date range -->
                <div class="form-group form-row">
                  <label class="col-md-3 col-form-label" for="created_at">Date range</label>
                  <div class="col-md-9">
                    <date-picker type="datetime" input-class="form-control" v-model="filter.created_at" range
                                 lang="en" format="YYYY-MM-DD hh:mm:ss" confirm id="created_at"
                                 value-type="YYYY-MM-DD hh:mm:ss"></date-picker>
                  </div>
                </div>
              </div>

            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-9 offset-md-3">
                    <button type="submit" class="btn btn-common m-2px"><i class="fas fa-search"></i> Search</button>
                    <button type="reset" class="btn btn-warning" @click="resetFilter()"><i class="fas fa-undo"></i> Reset</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div class="card-body table-responsive p-0">
          <table class="table">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>User</th>
                    <th>Method</th>
                    <th>Path</th>
                    <th>IP</th>
                    <th>Input</th>
                    <th>Created At</th>
<!--                    <th class="text-right">Action</th>-->
                </tr>
            </thead>
            <tbody class="list">
                <tr v-for="(val, index) in list.data" :key="index">
                    <td>{{ val.id }}</td>
                    <td>{{ val.user_id }}</td>
                    <td>{{ val.method }}</td>
                    <td>{{ val.path }}</td>
                    <td>{{ val.ip }}</td>
                    <td><pre>{{ val.input | pretty }}</pre></td>
                    <td>{{ val.created_at }}</td>
<!--                    <td class="text-right">
                        <a v-if="actionDelete == true" class="btn btn-danger btn-sm m-2px" href="javascript:void(0)" @click="deleteObject(val.id, index)"><i class="fas fa-trash"></i></a>
                    </td>-->

                </tr>
            </tbody>
          </table>
          <div class="p-4" v-if="_.isEmpty(list.data) && is_loading == false">
            <div class="alert alert-default-warning" role="alert">
              No data found!
            </div>
          </div>
        </div>

        <!-- /.card-body -->
        <div class="card-footer">
          <vue-pagination
            :pagination="list"
            v-if="list.total > list.per_page"
            @paginate="reload()"
          ></vue-pagination>
        </div>
        <!-- /.card-footer-->
      </div>

    </section>
  </div>
</template>

<script>
import VuePagination from '@/components/partials/PaginationComponent'
import axios from 'axios'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { CommonAPI } from '@/services/api'

export default {
  name: 'OperationLogList',
  components: {
    DatePicker, VuePagination
  },
  data: () => ({
    list: {
      total: 0,
      per_page: 1,
      from: 1,
      to: 0,
      current_page: 1
    },
    actionView: true,
    actionEdit: true,
    actionDelete: true,
    api_url: '',
    showFilter: false,
    filter: {},
    dateRange: '',
    pre: [],
    is_loading: false,
    empty: false,
    empty_message: '',
    users: []
  }),
  filters: {
    pretty: function (value) {
      return JSON.stringify(JSON.parse(value), null, 2)
    }
  },
  mounted: function () {
    this.api_url = '/get-operation-logs'
    this.initialize()
    this.getUsers()
  },
  methods: {
    async initialize () {
      this.filter = JSON.parse(JSON.stringify(this.$route.query))
      console.log('this.filter,', this.filter)
      // eslint-disable-next-line no-undef
      if (!_.isEmpty(this.filter)) {
        this.showFilter = true
      }
      this.is_loading = true

      await this.getList()
        .then((response) => {
          this.list = response.data
        })
        .catch((error) => {
          this.empty = true
          if (error.response.status === 403) {
            this.has_permission = false
          }
        })
      this.is_loading = false
    },
    getList: function () {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const _this = this
      _this.is_loading = true
      return new Promise((resolve, reject) => {
        /* let params = { page: _this.list.current_page }
        params = { ..._this.filter, ...params } */
        const params = { ...this.filter, ...this.$route.query }
        /*
        this.$CBSAccountingInstance.get(_this.api_url, {
          params: params
        })
         */
        axios.get(_this.api_url, {
          params: params
        }).then(response => {
          resolve(response)
        }).catch(error => {
          /* _this.empty = true
          _this.empty_message = error.error.message */
          reject(error)
          console.log('error', error.response.data)
        }).finally(() => {
          _this.is_loading = false
        })
      })
    },
    reload: function () {
      this.getList().then(response => {
        this.list = response.data
      }).catch(error => {
        this.empty = true
        if (error.response.status === 403) {
          this.has_permission = false
        }
      })
    },
    // getUsers() {
    //   this.is_loading = true;
    //   this.apiUsers(this.list.current_page, this.filter).then(list => {
    //     this.users = list;
    //   }).finally(() => {
    //     this.is_loading = false;
    //   });
    // },
    async getUsers () {
      await CommonAPI.getAllUsers().then(users => {
        console.log(users)
        this.users = users
      })
    },
    resetFilter () {
      this.list.current_page = 1
      this.filter = {}
      this.getList()
    },
    deleteObject: function (id, index) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const _this = this
      // eslint-disable-next-line camelcase
      const delete_api_url = '/get-operation-logs/' + id

      _this.$swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          _this.is_loading = true
          axios.delete(delete_api_url)
            .then(response => {
              _this.list.data.splice(index, 1)
              _this.$toaster.success(response.data.message)
              _this.is_loading = false
            })
            .catch(error => {
              _this.is_loading = false
              console.log(error)
              _this.$swal.fire(
                'Failed!',
                'failed to delete.',
                'error'
              )
            })
            .finally(() => {
              _this.is_loading = false
            })
        }
      })
    }
  }
}
</script>
