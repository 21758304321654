<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Departments</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'Home'}">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Departments</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="card">
          <div class="card-header">
            <a href="javascript:void(0)" class="btn btn-info" @click="showFilter = !showFilter"><i
              class="fa fa-filter"></i>Filter</a>
            <div class="card-tools">
              <router-link :to="{ name: 'DepartmentForm'}" class="btn btn-success"><i class="fas fa-plus"></i> New
              </router-link>
            </div>
          </div>
          <form @submit.prevent="getDepartments" method="GET" novalidate>
            <div class="card-body" v-if="showFilter">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group form-row">
                    <label class="col-md-3 col-form-label" for="name">Name</label>
                    <div class="col-md-9">
                      <input type="text" id="name" class="form-control" v-model="filter.name" placeholder="Name">
                    </div>
                  </div>
                  <div class="form-group form-row">
                    <label class="col-md-3 col-form-label" for="starting_date">Starting date</label>
                    <div class="col-md-9">
                      <date-picker type="date" id="starting_date" input-class="form-control"
                                   v-model="filter.starting_date" lang="en"
                                   format="YYYY-MM-DD" value-type="YYYY-MM-DD"></date-picker>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <!-- Date range -->
                  <div class="form-group form-row">
                    <label class="col-md-3 col-form-label" for="created_at">Created Date Range</label>
                    <div class="col-md-9">
                      <date-picker type="datetime" id="created_at" input-class="form-control"
                                   v-model="filter.created_at" range
                                   lang="en" format="YYYY-MM-DD hh:mm:ss" confirm
                                   value-type="YYYY-MM-DD hh:mm:ss"></date-picker>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-9 offset-md-3">
                      <button type="submit" class="btn btn-common m-2px">Search</button>
                      <button type="reset" class="btn btn-warning" @click="resetFilter()">Reset</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div class="overlay" v-if="is_loading">
            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
          </div>
          <div class="card-body table-responsive-md p-0">
            <table class="table">
              <thead>
              <tr>
                <th style="width: 10px">ID</th>
                <th>Name <sort-icons :_sort="'name'"/></th>
                <th>Organization</th>
                <th>Head Of Department</th>
                <th>Description</th>
                <th>Starting Date</th>
                <th class="text-right">Action</th>
              </tr>
              </thead>
              <tbody class="department-list">
              <tr v-for="(department, index) in departments.data">
                <td>{{ department.id }}</td>
                <td>{{ department.name }}</td>
                <td>{{ !_.isEmpty(department.organization) ? department.organization.org_name : 'NA' }}</td>
                <td>{{ department.admin_user.name }}</td>
                <td>{{ !_.isEmpty(department.description) ? department.description : 'NA' }}</td>
                <td><span class="badge bg-success">{{ department.starting_date }}</span></td>
                <td class="text-right">
                  <router-link :to="{ name: 'DepartmentShow', params: { id: department.id }}"
                               class="btn btn-info btn-sm m-2px">
                    <i class="fas fa-eye"></i>
                  </router-link>
                  <router-link :to="{ name: 'DepartmentEdit', params: { id: department.id }}"
                               class="btn btn-info btn-sm m-2px">
                    <i class="fas fa-pencil-alt"></i>
                  </router-link>
                  <button class="btn btn-danger btn-sm m-2px" @click="destroy(department.id, index)">
                    <i class="fas fa-trash"></i></button>
                </td>
              </tr>
              </tbody>
            </table>

            <div class="p-4" v-if="_.isEmpty(departments.data)">
              <div class="alert alert-default-warning" role="alert">
                No data found!
              </div>
            </div>
          </div>
          <div class="card-footer">
            <vue-pagination
              :pagination="departments"
              v-if="departments.total > departments.per_page"
              @paginate="getDepartments()"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import VuePagination from "@/components/partials/PaginationComponent";
import SortIcons from "@/components/utils/SortIcons";

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import {DepartmentsAPI} from "@/services/api";

export default {
  name: 'DepartmentList',
  components: {
    VuePagination, DatePicker, SortIcons
  },
  data: () => ({
    departments: {
      total: 0,
      per_page: 1,
      from: 1,
      to: 0,
    },
    showFilter: false,
    filter: {},
    is_loading: false
  }),
  methods: {
    getDepartments() {
      this.is_loading = true;
      let query = {...this.filter, ...this.$route.query}
      DepartmentsAPI.index(query).then(departments => {
        this.departments = departments;
      }).finally(() => {
        this.is_loading = false;
      });
    },
    destroy(id, index) {
      this.$swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        confirmButtonText: 'Yes, delete it!',
        icon: 'warning',
        showCancelButton: true, confirmButtonColor: '#3085d6', cancelButtonColor: '#d33',
      }).then((result) => {
        if (result.isConfirmed) {
          this.is_loading = true;
          DepartmentsAPI.destroy(id)
            .then(response => {
              this.departments.data.splice(index, 1);
              this.departments.total--;
              this.$toaster.success(response.message);
            })
            .catch(error => {
              console.log("Could not delete this:: ", error);
              this.$swal.fire('Failed!', 'Failed to delete.', 'error');
            }).finally(() => {
            this.is_loading = false;
          });
        }
      });
    },
    resetFilter() {
      const query = Object.assign({}, this.$route.query);
      if (Object.keys(query).length > 0) {
        delete query._sort
        delete query._sort_type
        delete query.page
        this.$router.replace({query});
      }
      this.filter = {};
      this.getDepartments();
    },
  },
  mounted() {
    this.getDepartments();
    this.$root.$on('sort-data', () => {
      this.$root.$emit('show-icon');
      this.getDepartments();
    });
  }
}
</script>
