<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Permissions</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'Home'}">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Permissions</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="card">
          <div class="card-header">
            <a href="javascript:void(0)" class="btn btn-info" @click="showFilter = !showFilter"><i
              class="fa fa-filter"></i>Filter</a>
            <div class="card-tools">
              <router-link :to="{name: 'PermissionForm'}" class="btn btn-success">
                <i class="fas fa-plus"></i> New
              </router-link>
              <button class="btn btn-info" @click="exportFile()">
                <i class="fas fa-download"></i> Export
              </button>
            </div>
          </div>
          <form @submit.prevent="getPermissions" method="GET" novalidate>
            <div class="card-body" v-if="showFilter">
              <div class="form-row">
                <div class="col-md-6">
                  <div class="form-group form-row">
                    <label class="col-md-3 col-form-label" for="name">Name</label>
                    <div class="col-md-9">
                      <input type="text" class="form-control" id="name" v-model="filter.name" placeholder="Name">
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group form-row">
                    <label class="col-md-3 col-form-label" for="http_path">Http Path</label>
                    <div class="col-md-9">
                      <input type="text" class="form-control" id="http_path" v-model="filter.http_path"
                             placeholder="HTTP PATH">
                    </div>
                  </div>
                </div>

              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-9 offset-md-3">
                      <button type="submit" class="btn btn-common m-2px"><i class="fas fa-search"></i> Search</button>
                      <button type="reset" class="btn btn-warning" @click="resetFilter()"><i class="fas fa-undo"></i>
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div class="overlay" v-if="is_loading">
            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
          </div>
          <div class="card-body table-responsive-md p-0">

            <table class="table ">
              <thead>
              <tr>
                <th style="width: 10px">ID</th>
                <th>Slug <sort-icons :_sort="'slug'"/></th>
                <th>Name <sort-icons :_sort="'name'"/></th>
                <th>Method</th>
                <th>Route</th>
                <th>Created At</th>
                <th>Updated At</th>
                <th class="text-right">Action</th>
              </tr>
              </thead>
              <tbody class="permissions-permissions">
              <tr v-for="(val, index) in permissions.data">
                <td>{{ val.id }}</td>
                <td>{{ val.slug }}</td>
                <td>{{ val.name }}</td>
                <td><span class="badge bg-success" v-for="(methodValue, roleIndex) in val.http_method">{{
                    methodValue
                  }}</span></td>
                <td>{{ val.http_path }}</td>
                <td>{{ val.created_at }}</td>
                <td>{{ val.updated_at }}</td>
                <td class="text-right">
                  <router-link :to="{ name: 'PermissionView', params: { id: val.id }}"
                               class="btn btn-info btn-sm m-2px">
                    <i class="fas fa-eye"></i>
                  </router-link>
                  <router-link :to="{ name: 'PermissionEdit', params: { id: val.id }}"
                               class="btn btn-info btn-sm m-2px">
                    <i class="fas fa-pencil-alt"></i>
                  </router-link>
                  <a class="btn btn-danger btn-sm m-2px" href="javascript:void(0)" @click="destroy(val.id, index)"><i
                    class="fas fa-trash"></i></a>
                </td>
              </tr>
              </tbody>
            </table>

            <div class="p-4" v-if="_.isEmpty(permissions.data)">
              <div class="alert alert-default-warning" role="alert">
                No data found!
              </div>
            </div>
          </div>
          <div class="card-footer">
            <vue-pagination
              :pagination="permissions"
              v-if="permissions.total > permissions.per_page"
              @paginate="getPermissions()"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import VuePagination from "@/components/partials/PaginationComponent";
import SortIcons from "@/components/utils/SortIcons";
import {PermissionAPI} from "@/services/api";
import axios from 'axios';

const fileDownload = require('js-file-download');
import {Notification} from "element-ui";


export default {
  name: 'PermissionList',
  metaInfo: {
    title: "Permission | Dotlines"
  },
  components: {
    // DatePicker,
    VuePagination, SortIcons
  },
  data: () => ({
    permissions: {
      total: 0,
      per_page: 1,
      from: 1,
      to: 0,
    },
    showFilter: false,
    filter: {},
    is_loading: false,
  }),
  mounted: function () {
    if (this.isMobile()) {
      window.$(document).find('.push-menu-class').trigger('click');
    }
    this.getPermissions();
    this.$root.$on('sort-data', () => {
      this.$root.$emit('show-icon');
      this.getPermissions();
    });
  },
  methods: {
    exportFile: function () {
      this.is_loading = true;
      axios.get('/admin-permissions-export', {responseType: 'blob'})
        .then(response => {
          //console.log(response.data);
          fileDownload(response.data, 'admin-permissions.xlsx');
        })
        .catch((error) => {
          console.log("error " + error);
        })
        .finally(() => {
          this.is_loading = false;
          Notification.success({
            title: "Export Completed",
            message: "Export operation completed successfully",
            type: "success"
          });
        });
    },
    getPermissions() {
      this.is_loading = true;
      let query = {...this.filter, ...this.$route.query}
      PermissionAPI.index(query).then(permissions => {
        this.permissions = permissions;
      }).finally(() => {
        this.is_loading = false;
      });
    },
    destroy: function (id, index) {
      this.$swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.is_loading = true;
          PermissionAPI.destroy(id)
            .then(response => {
              console.log("deleted this:: ", response);
              this.permissions.data.splice(index, 1);
              this.permissions.total--;
              this.$toaster.success(response.message);
            })
            .catch(error => {
              console.log("Could not delete this:: ", error);
              this.$swal.fire('Failed!', 'Failed to delete.', 'error');
            }).finally(() => {
            this.is_loading = false;
          });
        }
      });
    },
    resetFilter() {
      const query = Object.assign({}, this.$route.query);
      if (Object.keys(query).length > 0) {
        delete query._sort
        delete query._sort_type
        delete query.page
        this.$router.replace({query});
      }
      this.filter = {};
      this.getPermissions();
    },
  }
}
</script>

