<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Roles</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'Home'}">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Roles</li>
            </ol>
          </div>
        </div>
      </div>
    </section>
    <section class="content">

      <div class="card" id="role-add">
        <div class="overlay" v-if="is_loading">
          <i class="fas fa-2x fa-sync-alt fa-spin"></i>
        </div>
        <form @submit.prevent="handleSubmission()" method="POST" novalidate>
          <div class="card-header">
            <h3 class="card-title" v-if="!is_edit">Create</h3>
            <h3 class="card-title" v-if="is_edit">Edit</h3>
            <div class="card-tools">
              <router-link class="btn btn-info" :to="{name: 'Role'}"><i class="fas fa-roles"></i> List
              </router-link>
            </div>
          </div>
          <div class="card-body">
            <div class="form-group required">
              <label for="slug" class="control-label">Slug</label>
              <input type="text" id="slug" name="slug"
                     v-bind:class="{'has-error' : errors.has('slug')}"
                     v-validate="'required'"
                     v-model="roles.slug" class="form-control name"
                     placeholder="slug">
              <div class="help text-danger" v-show="errors.has('slug')">
                {{ errors.first('slug') }}
              </div>
            </div>
            <div class="form-group required">
              <label for="name" class="control-label">Name</label>
              <input type="text" id="name" name="name"
                     v-bind:class="{'has-error' : errors.has('name')}"
                     v-validate="'required'"
                     v-model="roles.name" class="form-control name"
                     placeholder="Name">
              <div class="help text-danger" v-show="errors.has('name')">
                {{ errors.first('name') }}
              </div>
            </div>

            <div class="form-group required">
              <label class="control-label">Organization</label>
              <multiselect
                name="organization"
                v-model="roles.organization_id"
                :options="organizations.map(org => org.id)"
                :custom-label="opt => organizations.find(x => x.id == opt).org_name"
                placeholder="Pick Organization" :searchable="true" :multiple="false"
                v-bind:class="{'has-error' : errors.has('organization')}"
                v-validate="'required'"
                class="organization">
              </multiselect>
              <div class="help text-danger" v-show="errors.has('organization')">
                {{ errors.first('organization') }}
              </div>
            </div>

            <div class="form-group">
              <label class="control-label">Permission Name</label>
              <multiselect
                name="Permission"
                v-model="roles.custom_permission_id"
                :options="custom_permissions.map(per => per.id)"
                :custom-label="opt => custom_permissions.find(x => x.id == opt).permission_name"
                placeholder="Pick Permission" :searchable="true" :multiple="false"
                v-bind:class="{'has-error' : errors.has('Permission')}"
                v-validate="''"
                class="organization">
              </multiselect>
              <div class="help text-danger" v-show="errors.has('Permission')">
                {{ errors.first('Permission') }}
              </div>
            </div>


            <div class="form-group required">
              <label class="control-label">Permissions</label>
              <el-transfer
                filterable
                :filter-method="filterMethod"
                filter-placeholder="Select Permissions"
                v-model="roles.permissions"
                :data="permission_data">
              </el-transfer>
<!--              @change="handleChange"-->
              <div class="help text-danger" v-show="errors.has('permission_err')">
                {{ errors.first('permission_err') }}
              </div>
            </div>
          </div>
          <div class="card-footer">
            <button type="submit" v-if="!is_edit" class="btn btn-primary">Submit</button>
            <button type="submit" v-if="is_edit" class="btn btn-primary">Update</button>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>
<script>
import { CommonAPI, RoleAPI } from '@/services/api'

export default {
  name: 'RoleForm',
  metaInfo: {
    title: 'Role | Dotlines'
  },
  components: {
    //
  },
  data: () => ({
    roles: {},
    organizations: [],
    params: {},
    is_edit: false,
    isSelectorLoading: false,
    is_loading: false,
    permission_data: [],
    custom_permissions: []
  }),
  mounted: function () {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _this = this
    _this.initialLoad()
  },
  methods: {
    changePermissionSubmitStruc () {
      const custPermissionId = []
      this.roles.permissions.forEach((singlePermission, index) => {
        custPermissionId.push({
          id: singlePermission
        })
      })
      // this.$delete(this.roles, 'permissions');
      this.roles.permissions = custPermissionId
    },

    handleSubmission () {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const _this = this
      _this.$validator.validateAll().then((result) => {
        if (result) {
          this.changePermissionSubmitStruc()
          if (this.is_edit) {
            RoleAPI.update(this.params.id, this.roles).then(response => {
              this.$router.push({ name: 'Role' })
              this.$toaster.success(response.message)
            }).catch(error => {
              console.log(error)
              this.$setErrorsFromResponse(error.data)
            })
          } else {
            RoleAPI.store(this.roles).then(response => {
              this.$router.push({ name: 'Role' })
              this.$toaster.success(response.message)
            }).catch(error => {
              console.log('create', error)
              this.$setErrorsFromResponse(error.data)
            })
          }
        }
      })
    },
    filterMethod (query, item) {
      return item.initial.toLowerCase().indexOf(query.toLowerCase()) > -1
    },
    handleChange (value, direction, movedKeys) {
      console.log('check el', value, direction, movedKeys)
      console.log('check our left data', this.roles.permissions, typeof this.roles.permissions)
      console.log('check our right data', this.permission_data, typeof this.permission_data)
    },

    // renderFunc(h, option) {
    //   console.log('render content',h,option);
    //   // return <span>{ option.key } - { option.label }</span>;
    // },

    async getOrganizations () {
      await CommonAPI.getAllOrganizations().then(organizations => {
        this.organizations = organizations
        // console.log('permissions org', organizations);
      })
    },
    async  getCustomPermission () {
      // eslint-disable-next-line camelcase
      await CommonAPI.getCustomPermissionLists().then(cust_role_name => {
        // console.log('cust_role_name',cust_role_name);
        // eslint-disable-next-line camelcase
        this.custom_permissions = cust_role_name
      })
    },
    async roleShow (id) {
      await RoleAPI.show(id).then(roles => {
        // eslint-disable-next-line no-undef,valid-typeof
        if (!_.isEmpty(roles.organization) && typeof (roles.organization) !== undefined) {
          this.organizations[0] = roles.organization
        }
        // eslint-disable-next-line no-undef,valid-typeof
        if (!_.isNull(roles.custom_permission_id)) {
          this.custom_permissions[0] = roles.custom_permission
        }
        this.roles = roles

        // eslint-disable-next-line no-undef,valid-typeof
        if (!_.isEmpty(roles.permissions) && typeof (roles.permissions) !== undefined) {
          // eslint-disable-next-line camelcase
          const permission_data_cust1 = []
          roles.permissions.forEach((singlePermission, index) => {
            permission_data_cust1.push(singlePermission.id)
          })
          // eslint-disable-next-line camelcase
          this.roles.permissions = permission_data_cust1
        }
      })
    },
    async generateData () {
      CommonAPI.getPermissions().then(permissions => {
        // eslint-disable-next-line camelcase
        const permission_data_cust = []
        permissions.forEach((singlePermission, index) => {
          permission_data_cust.push({
            label: singlePermission.name,
            key: singlePermission.id,
            initial: singlePermission.name
          })
        })
        // eslint-disable-next-line camelcase
        this.permission_data = permission_data_cust
      })
    },
    async initialLoad () {
      this.is_loading = true
      this.params = this.$route.params
      // eslint-disable-next-line no-undef
      if (!_.isEmpty(this.params) && this.params.id !== undefined) {
        this.is_edit = true
      }
      if (this.is_edit) {
        await this.roleShow(this.params.id)
      }
      await this.getOrganizations()
      await this.getCustomPermission()
      await this.generateData()
      this.is_loading = false
    }
  }
}
</script>
