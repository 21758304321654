<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Permissions</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'Home'}">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Permissions</li>
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <section class="content">

      <!-- Default box -->
      <div class="card" id="users">
        <div class="overlay" v-if="is_loading">
          <i class="fas fa-2x fa-sync-alt fa-spin"></i>
        </div>
        <div class="card-header">
          <h3 class="card-title">Permission Detail</h3>
          <div class="card-tools">
            <router-link class="btn btn-info" :to="{name: 'Permissions'}"><i class="fas fa-list"></i> List
            </router-link>
          </div>
        </div>
        <div class="card-body">

          <div class="custom-list">
            <ul>
              <li><strong>ID</strong> {{ permissions.id }}</li>
              <li><strong>Name</strong>{{ permissions.name }}</li>
              <li><strong>Slug</strong>{{ permissions.slug }}</li>
              <li><strong>Organization</strong>{{ !_.isEmpty(permissions.organization) ? permissions.organization.org_name : 'N/A' }}</li>
              <li><strong>Http Method</strong>
                <span class="badge bg-success" v-for="(httpVal, httpIndex) in permissions.http_method">{{
                  httpVal
                }}</span>
              </li>
              <li><strong>Http Path</strong>{{ permissions.http_path }}</li>
              <li><strong>Created At</strong>{{ !_.isEmpty(permissions.created_at) ? permissions.created_at : '' }}</li>
              <li><strong>Updated At</strong>{{ !_.isEmpty(permissions.updated_at) ? permissions.updated_at : '' }}</li>
            </ul>
          </div>
        </div>
        <!-- /.card-body -->
        <div class="card-footer">

        </div>
        <!-- /.card-footer-->
      </div>
      <!-- /.card -->


    </section>
    <!-- /.content -->
  </div>
</template>

<script>
import {PermissionAPI} from "@/services/api";

export default {
  name: 'PermissionView',
  metaInfo: {
    title: "Permission | Dotlines"
  },
  components: {},
  data: () => ({
    params: {},
    permissions: {},
    is_loading: false
  }),
  mounted: function () {
    let _this = this;
    _this.param = _this.$route.params;
    _this.permissionsShow(_this.param.id);
  },
  methods: {
    permissionsShow: function (id) {
      PermissionAPI.show(id).then(permissions => {
        this.permissions = permissions;
        // this.getALLMethods();
        console.log('view permissions', permissions);
      });
    },
  },
}
</script>

